
import { defineComponent, reactive, toRefs } from 'vue'

import { url } from '@/service/config'
import objIsFalse from '@/comm/utils/obj-false'

export default defineComponent({
    name: 'SingleImg',
    emits: ['showFileDesc'],
    props: ['singleData'],
    setup(props, ctx) {
        let t = reactive<any>({
            data: {},
            active: { menu_1: true, menu_2: false },
            url
        })
        const clickMneu = (menu: string) => {
            objIsFalse(t.active)
            t.active[menu] = true
        }
        const showFileDesc = (index: number) => {
            ctx.emit('showFileDesc', index)
        }

        return { ...toRefs(t), clickMneu, showFileDesc }
    }
})
