
import { defineComponent, reactive, toRefs } from 'vue'

import { url } from '@/service/config'
import objIsFalse from '@/comm/utils/obj-false'

export default defineComponent({
    name: 'ColorMaterial',
    props: ['singleData'],
    setup() {
        let t = reactive<any>({
            data: {},
            active: { menu_1: true, menu_2: false, meun_3: false },
            bigImg_: '',
            url
        })
        const clickMneu = (menu: string) => {
            objIsFalse(t.active)
            t.active[menu] = true
        }
        const bigImg = (ImgUrl: string) => {
            t.bigImg_ = url + ImgUrl
            console.log(t.bigImg_)
        }

        return { ...toRefs(t), clickMneu, bigImg }
    }
})
