import { wuRequest } from '@/service'
import { ElMessageBox } from 'element-plus'
import { useRoute } from 'vue-router'

const init = async (t: any, id: string) => {
    const res = await wuRequest.get({
        url: '/production/' + id
    })
    if (res.data.code === 10001) {
        t.data = res.data.data.data
        // 处理下拉菜单的数据
        t.SingleImgData = t.data.descInfo
        // 处理大场景图和功能菜单
        const sceneArr = []
        const fnDescArr = []

        for (let i = 0; i < t.data.descimg.length; i++) {
            if (t.data.descimg[i].desc_category_name === '场景图') {
                sceneArr.push(t.data.descimg[i])
            }
            if (t.data.descimg[i].is_fn === 2) {
                if (t.data.descimg[i].desc_category_name === '头枕调节') {
                    t.data.descimg[i].fn_avtice = 2
                }
                fnDescArr.push(t.data.descimg[i])
            }
        }
        //处理页脚下面的轮播图
        const footCarouseArr = []
        for (let i = 0; i < t.data.descInfo.length; i++) {
            if (t.data.descInfo[i].is_desc_carousel == 1) {
                footCarouseArr.push(t.data.descInfo[i])
            }
        }
        t.lastSceneImg = sceneArr.pop()
        t.bigSceneImg = sceneArr
        t.fnDescArr = fnDescArr
        t.footCarouse = footCarouseArr

        //处理功能介绍
    } else {
        ElMessageBox.alert('数据请求错误，请稍后重试', '数据异常', {
            confirmButtonText: 'OK'
        })
        return false
    }
}
export { init }
