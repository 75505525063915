// 反
export default function objIsFalse(obj: any, isFalse = true, keyName = 'name') {
    const o: any = obj
    for (const key in o) {
        if (key !== keyName && isFalse && key !== 'en_name') {
            obj[key] = false
        }
    }
    return o
}
