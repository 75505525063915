
import { defineComponent, reactive, toRefs, onMounted, ref } from 'vue'

import Lb from '@/components/Lb.vue'
import { url } from '@/service/config'
import { useRoute } from 'vue-router'
import SingleImg from '@/components/production/SingleImg.vue'
import File from '@/components/production/File.vue'
import ColorMaterial from '@/components/production/ColorMaterial.vue'
import objIsFalse from '@/comm/utils/obj-false'
import { init } from './hooks/production'

export default defineComponent({
    components: {
        Lb,
        SingleImg,
        File,
        ColorMaterial
    },

    setup() {
        let bigVideo = ref<any>(null)
        const setRef = ref([])
        const id = useRoute().params.id
        const arr = ref([1, 2, 3]) //测试数据
        let t = reactive<any>({
            data: {},
            SingleImgData: [],
            // 大场景图
            bigSceneImg: [],
            // 最后一张场景图
            lastSceneImg: {},
            //功能介绍
            fnDescArr: [],
            // 页脚下面的轮播
            footCarouse: [],
            footCurrentIndex: 0,
            footCurrentPage: { start: 0, end: 2 },
            url,
            id,
            bigImg: true,
            video: [
                '力优',
                'NEOSEAT',
                '名悦',
                'TENDER FORM',
                '威达',
                'VIDA',
                '悦尚云霄',
                'ROC-CHAIR',
                '星锐',
                'ISEE',
                '炫变',
                'DVARY',
                '美力特',
                'MARRIT',
                '英菲尼斯',
                'INFLEX',
                '锋尚',
                'IVINO',
                '流彩',
                'LAYA'
            ],
            //     状态控制
            descMenu: [
                // isActive 是否为激活状态  show_1 状态下面的1 2 3 图片，细节 ，场节的意思
                { name: '单品图片', en_name: 'Images', isActive: false, show_1: false, show_2: false, show_3: false },
                { name: '资料文件', en_name: 'Documents', isActive: false, show_1: false, show_2: false, show_3: false },
                { name: '颜色材质', en_name: 'Materials', isActive: false, show_1: false, show_2: false, show_3: false }
            ],
            basePublice: '@/assets/video/'
        })
        onMounted(async () => {
            // 保持菜单更新一直在顶部
            document.body.scrollTop = document.documentElement.scrollTop = 0
            init(t, id as string)
        })
        // watch(
        //     useRoute(),
        //     (n) => {
        //         //找到以/production/ 这个开头的就执行页面重新初始化
        //         if (n.path.indexOf('/production/') !== -1) {
        //             history.go(0)
        //         }
        //     },
        //     { deep: true }
        // )
        const clickDescMenu = (i: number) => {
            //为真表示点击的是已经活的菜单，需要把自已点成非激活的状态
            if (t.descMenu[i].isActive) {
                t.descMenu[i] = objIsFalse(t.descMenu[i])
                // 表示单击其它菜单，这时需要把其它菜单做非激活状态然后在把激活指定的菜单
            } else {
                // eslint-disable-next-line for-direction
                for (let i = 0; i < t.descMenu.length; i++) {
                    t.descMenu[i] = objIsFalse(t.descMenu[i])
                }
                t.descMenu[i].isActive = true
            }
        }
        //文件资料那里是否要显示证书和详情，默认为1显示 2不显示
        const showFileDesc = (i: number) => {
            if (t.SingleImgData[i].is_show === 2) {
                t.SingleImgData[i].is_show = 1
            } else {
                t.SingleImgData.map((item: any) => {
                    item.is_show = 1
                })
                t.SingleImgData[i].is_show = 2
            }
        }
        // 功能菜单
        const changFnMenu = (i: number) => {
            // 如果为2代表当前激活的菜单需要收起来，否则就是激活指定菜单
            if (t.fnDescArr[i].fn_avtice === 2) {
                t.fnDescArr.map((item: any) => (item.fn_avtice = 1))
            } else {
                t.fnDescArr.map((item: any) => (item.fn_avtice = 1))
                t.fnDescArr[i].fn_avtice = 2
            }
        }
        //页脚轮播图处理
        const changeFootDesc = (p: string) => {
            if (p === '-') {
                t.footCurrentIndex = t.footCurrentIndex - 1
                t.footCurrentIndex <= 0 ? (t.footCurrentIndex = 0) : ''
                t.footCarouse[t.footCurrentIndex].is_show = 2
            } else if (p === '+') {
                t.footCurrentIndex = t.footCurrentIndex + 1
                t.footCurrentIndex >= t.footCarouse.length - 1 ? (t.footCurrentIndex = t.footCarouse.length - 1) : ''
                t.footCarouse[t.footCurrentIndex].is_show = 2
            } else if (p === 'x') {
                t.footCarouse[t.footCurrentIndex].is_show = 1
            }
        }
        const changeFootImg = (p: string) => {
            if (p === '-') {
                if (t.footCurrentPage.start > 0) {
                    t.footCurrentPage.start = t.footCurrentPage.start - 2
                    t.footCurrentPage.end = t.footCurrentPage.start + 2
                } else if (t.footCurrentPage.start <= 0) {
                    t.footCurrentPage.start = 0
                    t.footCurrentPage.end = 2
                }
            } else if (p === '+') {
                if (t.footCurrentPage.end < t.footCarouse.length) {
                    t.footCurrentPage.end = t.footCurrentPage.end + 2
                    t.footCurrentPage.sart = t.footCurrentPage.end - 2
                } else {
                    t.footCurrentPage.end = t.footCarouse.length
                    t.footCurrentPage.sart = t.footCurrentPage.end - 2
                }
            }
        }
        //显示大页脚大图详细资料
        const showDesc = (index: number) => {
            t.footCurrentIndex = index
            t.footCarouse[index].is_show = 2
        }
        const playStart = () => {
            bigVideo.value?.play()
            t.bigImg = false
        }

        return { ...toRefs(t), clickDescMenu, showFileDesc, playStart, changFnMenu, changeFootDesc, showDesc, changeFootImg, setRef, arr, bigVideo }
    }
})
