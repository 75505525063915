import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-136153e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-1 pt-0" }
const _hoisted_2 = {
  key: 0,
  class: "row p-0 m-0"
}
const _hoisted_3 = {
  key: 0,
  class: "col-6 singleList d-flex flex-column"
}
const _hoisted_4 = ["src", "onClick"]
const _hoisted_5 = { class: "big-img" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.active.menu_1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.singleData, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (item.url && item.show_type === 1 && item.desc_type === 4)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("img", {
                      class: "w-100 imgMaterial",
                      src: _ctx.url + item.url,
                      alt: "",
                      onClick: ($event: any) => (_ctx.bigImg(item.url))
                    }, null, 8, _hoisted_4),
                    _createElementVNode("span", null, _toDisplayString(item.title), 1),
                    _createElementVNode("span", null, _toDisplayString(item.desc), 1)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.bigImg_ !== '')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.bigImg_ = '')),
          class: "big-img-parent"
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _ctx.bigImg_,
              alt: "",
              class: "w-100 h-100"
            }, null, 8, _hoisted_6)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}