import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c3192a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-1 pt-0" }
const _hoisted_2 = { class: "descMenu d-flex justify-content-around" }
const _hoisted_3 = {
  key: 0,
  class: "row p-0 m-0"
}
const _hoisted_4 = {
  key: 0,
  class: "col-6 singleList"
}
const _hoisted_5 = ["src", "onClick"]
const _hoisted_6 = {
  key: 1,
  class: "row p-0 m-0"
}
const _hoisted_7 = {
  key: 0,
  class: "col-12 singleList"
}
const _hoisted_8 = ["src", "onClick"]
const _hoisted_9 = {
  key: 2,
  class: "row p-0 m-0"
}
const _hoisted_10 = {
  key: 0,
  class: "col-6 singleList"
}
const _hoisted_11 = ["src", "onClick"]
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass(["d-inline-block", { active: _ctx.active.menu_1 }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickMneu('menu_1')))
      }, "item", 2),
      _createElementVNode("span", {
        class: _normalizeClass(["d-inline-block", { active: _ctx.active.menu_2 }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clickMneu('menu_2')))
      }, "Environment", 2),
      _createElementVNode("span", {
        class: _normalizeClass(["d-inline-block", { active: _ctx.active.menu_3 }]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clickMneu('menu_3')))
      }, "Detail", 2)
    ]),
    (_ctx.active.menu_1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.singleData, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (item.url && item.show_type === 1 && item.desc_type === 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("img", {
                      class: "w-100 h-100",
                      src: _ctx.url + item.url,
                      alt: "",
                      onClick: ($event: any) => (_ctx.bigImg(item.url))
                    }, null, 8, _hoisted_5)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.active.menu_2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.singleData, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (item.url && item.show_type === 2 && item.desc_type === 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("img", {
                      class: "w-100 h-100",
                      src: _ctx.url + item.url,
                      alt: "",
                      onClick: ($event: any) => (_ctx.bigImg(item.url))
                    }, null, 8, _hoisted_8)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.active.menu_3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.singleData, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (item.url && item.show_type === 3 && item.desc_type === 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("img", {
                      class: "w-100 h-100",
                      src: _ctx.url + item.url,
                      alt: "",
                      onClick: ($event: any) => (_ctx.bigImg(item.url))
                    }, null, 8, _hoisted_11)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.bigImg_ !== '')
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "big-img",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.bigImg_ = ''))
        }, [
          _createElementVNode("img", {
            src: _ctx.bigImg_,
            alt: "",
            class: "w-100"
          }, null, 8, _hoisted_12)
        ]))
      : _createCommentVNode("", true)
  ]))
}